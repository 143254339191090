body {
  font-family: 'Montserrat', sans-serif;
  background: #f4f4f4 !important;
  color: #2F2F2F !important;
  background-position-x: -88px;
  background-position-y: 88px;
}

/* .row {
  margin-left: 0px;
  margin-right: 0px;
} */

#root {
  width: 100%;
}

/* * {
  outline: 1px solid #f00 !important;
} */

.navbar {
  border-bottom: 2px solid #ececec;
}

.navbar-brand {
  font-size: 22px;
  letter-spacing: 8px;
  font-weight: bold;
}

.nav-link {
  font-size: 18px;
  cursor: pointer;
}

a.nav-link:hover {
  color: #65CBE8 !important;
}

a.block-link {
  text-decoration: none;
}

a.block-link:hover {
  cursor: pointer;
}

li.nav-item {
  margin-left: 30px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.font-bold {
  font-weight: bold;
}

.exchange-campaign,
.exchange-campaign * {
  font-family: 'Spartan' !important;
}

.exchange-campaign a[disabled] {
  background: rgba(0, 0, 0, 0.25);
  color: #2F2F2F;
  pointer-events: none;
}

.exchange-campaign label {
  font-size: 12px;
  font-weight: normal;
  display: inline;
}

.ref-social-icons {
  width: 30px;
}

.ref-social-margin {
  margin-left: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-20 {
  margin-top: 20px;
}

.everest-follower-image {
  width: 20px;
}

.t-follower {
  font-size: 16px;
}

.nav-exchange-link {
  font-weight: bold;
  color: black !important;
}

.nav-exchange-link:hover {
  color: #65CBE8 !important;
}

.nav-exchange-connect-btn {
  padding: 8px 12px !important;
  border-radius: 100px;
  color: #2F2F2F !important;
  font-weight: bold;
  border: 3px solid #65CBE8;
  font-size: 14px !important;
}

.nav-exchange-signup-btn {
  padding: 8px 12px !important;
  border-radius: 100px;
  color: white !important;
  font-weight: bold;
  border: 3px solid #2F2F2F;
  background: #2F2F2F;
  font-size: 14px !important;
}

.nav-exchange-connect-btn:hover,
.nav-exchange-signup-btn:hover {
  color: inherit !important;
}

.exch-banner {
  position: relative;
}

.exch-banner-text {
  position: absolute;
  top: 15%;
  left: 150px;
  width: 45%;
}

.exch-banner-heading {
  color: #2F2F2F;
  margin-bottom: 10px;
  font-weight: 550;
  font-size: 4vw;
}

.exch-footer-reward {
  color: white;
  /* margin-bottom: 10px; */
  font-weight: 600;
  font-size: 3vw;
}

.exch-footer-amount {
  color: #65CBE8;
}

.exch-banner-reward {
  color: #65CBE8;
  font-weight: 900;
  font-size: 6vw;
}

.exch-banner-reward2 {
  color: #65CBE8;
  font-weight: 700;
  /* font-weight: bold;
  font-size: 6vw; */
}

.exch-banner-heading2 {
  color: #2F2F2F;
  font-size: 2vw;
}

.exch-banner-heading2-d {
  font-size: 12px;
}

.exch-banner-btn {
  margin-top: 4%;
  width: 60%;
  font-size: 18px !important;
}

.exch-banner-btn:hover {
  color: white !important;
}

.exch-rewards {
  background: #2F2F2F;
  padding: 15px;
  color: white;
  text-align: center;
  font-weight: bold;
}

.exch-gift-icon {
  margin-top: -7px;
  margin-right: 5px;
}

.exch-gift-clr {
  color: #2ec2e6;
}

.exch-features-parent {
  background: white;
}

.exch-features {
  padding: 30px;
  margin-top: 5%;
}

.exch-security {
  background: white;
  padding: 50px 0px 30px 70px;
}

.exch-reward-text {
  position: absolute;
  top: 50%;
  left: 26%;
  text-align: center;
}

.exch-buy {
  width: 10%;
}

.exch-service-wrapper {
  padding-top: 100px;
}

.exch-service {
  padding: 50px;
  background: white;
  border-radius: 6px;
}

.exch-banner-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 270px;
  background: url('./images/exch-reward.png');
  background-size: cover;
  background-position: center;
}

.exch-prod-serv {
  margin-top: 3%;
}

.exch-banner-footer {
  background: white;
  background-size: cover;
  background-position: center;
  padding: 75px 20px;
  margin-top: -7%;
  z-index: 10;
  position: relative;
}

.exch-footer-text {
  color: #2F2F2F;
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 12px;
}

.exch-footer-btn {
  border: 3px solid #65CBE8;
  background: #65CBE8 !important;
  color: #2f2f2f !important;
}

.exch-footer-sub {
  font-size: 10px;
  margin-top: 10px;
}

.prod-serv-image {
  width: 30%;
}

@media only screen and (min-width: 992px) {
  /* Desktop */
  .exch-heading {
    font-size: 34px;
  }

  .exchange-banner {
      /* height: 450px; */
      width: 100%;
  }

  .affiliate-bullet {
      width: 4%;
  }
  
  .prod-serv-image {
    width: 350px;
  }
}

@media only screen and (max-width: 600px) {
  /* Mobile */  
  .nav-con-mb {
      margin-bottom: 10px;
  }
  .exch-banner-2 {
      height: 190px;
  }
  .exch-service {
      padding: 10px;
  }

  .exch-service-wrapper {
      padding-top: 10px;
  }

  .ref2-points-bullet {
      padding: 0;
      flex: 0.01;
  }

  .ref2-points-text {
      flex: 1;
  }

  .exch-banner-heading2-d {
      font-size: 8px;
  }

  .exch-features {
      padding: 30px;
  }

  .exch-banner-text {
      left: 50px;
  }

  .exch-buy {
      width: 80%;
  }
}
